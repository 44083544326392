import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import MaintenanceChecker from "v4/features/front/maintenance/MaintenanceChecker/MaintenanceChecker";
import reducer from './reducers';
import './scss/main.scss';
import * as serviceWorker from './serviceWorker';
import './translation';
import {AuthProvider} from "./v4/contexts/AuthContext";
import NotificationProvider from "./v4/contexts/NotificationContext";
import './v4/utils/debounce.js';
import App from './views/App/App';

/**
 * @see https://medium.com/@vcarl/performance-profiling-a-redux-app-c85e67bf84ae
 *
 * @returns {function(*): function(*=): (*)}
 */
const userTiming = () => (next) => (action) => {
    if (performance.mark === undefined) return next(action);
    performance.mark(`${action.type}_start`);
    const result = next(action);
    performance.mark(`${action.type}_end`);
    performance.measure(
        `${action.type}`,
        `${action.type}_start`,
        `${action.type}_end`,
    );
    return result;
}

const store = createStore(
    reducer,
    applyMiddleware(thunk, userTiming),
);
ReactDOM.render(
    <AuthProvider>
        <MaintenanceChecker>
            <NotificationProvider>
                <Provider store={store}>
                    <App/>
                </Provider>
            </NotificationProvider>
        </MaintenanceChecker>
    </AuthProvider>,
    document.getElementById('root')
)
;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
